<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Travail pédagogique pour le DUT MMI</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2018</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end, webdesign, communication
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Chronos est un site web mobile first sur le sujet des mythes et de
						leurs croyances que j'ai réalisé lors du premier semestre en Métiers
						du Multimédia et de l'Internet. Il est le premier site web que j'ai
						réalisé. Celui-ci avait pour thématique « Le livre augmenté » qui
						consistait à réunir un livre emprunté à la bibliothèque
						universitaire ainsi qu'un film traitant de sujets similaires et d'en
						faire un site. <br /><br />J'avais pour cela choisi le livre « Les
						Grecs ont-ils cru à leurs mythes » de Paul Veyne que j'ai mis en
						relation avec le film « Le Choc des Titans » par Louis Leterrier.
						<br /><br />Ainsi, j'ai commencé par la partie communication du site
						et du projet en général en réalisant un dossier de communication.
						Dedans j'ai utilisé différents outils de communication et de gestion
						comme par exemple le SWOT, le GANTT, les personas etc. J'ai aussi
						noté les différentes idées que je souhaite transmettre au travers du
						site. J'ai ensuite réalisé la partie rédactionnel du site en format
						web pour les différents articles. Par la suite j'ai réalisé une
						maquette sur Adobe XD que j'ai intégré par la suite en HTML | CSS |
						Javascript en format mobile. En parallèle, j'ai expliqué mes choix
						graphiques par l'intermédiaire d'une charte graphique.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://antoninwinterstein.com/Dossier_de_communication_Chronos.pdf"
							class="boutonVoirSite"
							>VOIR LE DOSSIER DE COMMUNICATION</a
						>
					</div>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://antoninwinterstein.com/Intentions_et_charte_graphiques_Chronos.pdf"
							class="boutonVoirSite"
							>VOIR LA CHARTE GRAPHIQUE</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/chronos/livre_film.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Couverture du livre de Paul Veyne à
                gauche et affiche du film de Louis Leterrier à droite"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Couverture du livre de Paul Veyne à gauche et affiche du film de
						Louis Leterrier à droite
					</p>

					<!--Image 2-->

					<img
						src="../assets/chronos/maquette.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Maquette du site réalisée sur Adobe
                XD"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Maquette du site réalisée sur Adobe XD
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Chronos",
			meta: [
				{
					name: "description",
					content:
						'Découvrez le projet de création du site web mobile first "Chronos" réalisé par Antonin Winterstein !',
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "CHRONOS - SITE WEB MOBILE FIRST",
				urlImage: require("../assets/projets/fond_chronos.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
